import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './Location.css';

const mapContainerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 49.3587,
  lng: 6.1682
};

function Location() {
  return (
    <div className="location-container">
      <h1>Où nous trouver</h1>
      <div className="location-info">
        <p>KAD AUTO</p>
        <p>1 Rue du Général Castelnau</p>
        <p>57100 Thionville, France</p>
        <p>Téléphone: +33 3 82 XX XX XX</p>
        <p>Email: contact@kadauto.fr</p>
      </div>
      <LoadScript googleMapsApiKey="VOTRE_CLE_API_GOOGLE_MAPS">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={14}
        >
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default Location;
