import React from 'react';

function Footer() {
  return (
    <footer>
      <p>&copy; {new Date().getFullYear()} KAD AUTO. Tous droits réservés.</p>
    </footer>
  );
}

export default Footer;
