import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ContactForm from './ContactForm';

function CarDetails() {
  const [car, setCar] = useState(null);
  const [showTestDriveForm, setShowTestDriveForm] = useState(false);
  const [testDriveDate, setTestDriveDate] = useState('');
  const { id } = useParams();

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const response = await fetch(`https://ab-code.fr/api.php?action=getCarDetails&id=${id}`);
        const data = await response.json();
        setCar(data);
      } catch (error) {
        console.error('Erreur lors du chargement des détails de la voiture:', error);
      }
    };

    fetchCarDetails();
  }, [id]);

  const handleTestDriveSubmit = (e) => {
    e.preventDefault();
    console.log("Demande d'essai pour la voiture:", id, "Date:", testDriveDate);
    alert("Votre demande d'essai a été envoyée avec succès !");
    setShowTestDriveForm(false);
    setTestDriveDate('');
  };

  if (!car) {
    return <div className="loading">Chargement...</div>;
  }

  return (
    <div className="car-details-page">
      <div className="car-details-header">
        <Link to="/" className="back-link">&larr; Retour aux annonces</Link>
        <h1>{car.brand} {car.model}</h1>
      </div>
      <div className="car-details-content">
        <div className="car-image-gallery">
          <img src={car.image} alt={`${car.brand} ${car.model}`} className="car-image-main" />
          {/* Ajoutez ici une galerie d'images si disponible */}
        </div>
        <div className="car-info-sidebar">
          <div className="car-price-tag">{car.price} €</div>
          <div className="car-quick-specs">
            <div className="spec-item">
              <span className="spec-label">Année</span>
              <span className="spec-value">{car.year}</span>
            </div>
            <div className="spec-item">
              <span className="spec-label">Kilométrage</span>
              <span className="spec-value">{car.mileage} km</span>
            </div>
            <div className="spec-item">
              <span className="spec-label">Carburant</span>
              <span className="spec-value">{car.fuel}</span>
            </div>
            <div className="spec-item">
              <span className="spec-label">Transmission</span>
              <span className="spec-value">{car.transmission}</span>
            </div>
          </div>
          <button onClick={() => setShowTestDriveForm(!showTestDriveForm)} className="cta-button">
            {showTestDriveForm ? 'Annuler' : 'Réserver un essai'}
          </button>
          {showTestDriveForm && (
            <form onSubmit={handleTestDriveSubmit} className="test-drive-form">
              <h3>Réserver un essai</h3>
              <div className="form-group">
                <label htmlFor="testDriveDate">Date souhaitée :</label>
                <input
                  type="date"
                  id="testDriveDate"
                  value={testDriveDate}
                  onChange={(e) => setTestDriveDate(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-button">Envoyer la demande</button>
            </form>
          )}
        </div>
      </div>
      <div className="car-description">
        <h2>Description</h2>
        <p>{car.description}</p>
      </div>
      <div className="contact-section">
        <h2>Contacter le vendeur</h2>
        <ContactForm carId={car.id} />
      </div>
    </div>
  );
}

export default CarDetails;

