import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const admin = localStorage.getItem('isAdmin') === 'true';
    setIsLoggedIn(loggedIn);
    setIsAdmin(admin);
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.setItem('isAdmin', 'false');
    navigate('/');
  };

  return (
    <header>
      <div className="logo-container">
        <Link to="/" className="logo">KAD Auto</Link>
        <span className="logo-subtitle">La qualité au service de votre véhicule</span>
      </div>
      <nav>
        <ul>
          <li><Link to="/">Accueil</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li><Link to="/localisation">Localisation</Link></li>
          {isAdmin && (
            <li><Link to="/admin" className="admin-button">Admin</Link></li>
          )}
          {isLoggedIn ? (
            <li><button onClick={handleLogout}>Déconnexion</button></li>
          ) : (
            <li><Link to="/connexion">Connexion</Link></li>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
