import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminDashboard.css';

function AdminDashboard() {
  const [cars, setCars] = useState([]);
  const [newCar, setNewCar] = useState({
    brand: '', model: '', year: '', price: '', mileage: '', fuel: '', transmission: '', image: '', description: ''
  });
  const [testDriveRequests, setTestDriveRequests] = useState([]);
  const [messages, setMessages] = useState([]);
  const [activeTab, setActiveTab] = useState('cars');
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = () => {
      const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
      if (!isLoggedIn) {
        navigate('/connexion');
      } else {
        fetchCars();
        fetchTestDriveRequests();
        fetchMessages();
      }
    };

    checkLoginStatus();
    const intervalId = setInterval(checkLoginStatus, 5000);
    return () => clearInterval(intervalId);
  }, [navigate]);

  const fetchCars = () => {
    fetch('https://ab-code.fr/api.php?action=getCarList')
      .then(response => response.json())
      .then(data => setCars(data))
      .catch(error => console.error('Erreur:', error));
  };

  const fetchTestDriveRequests = () => {
    fetch('https://ab-code.fr/api.php?action=getTestDriveRequests')
      .then(response => response.json())
      .then(data => setTestDriveRequests(data))
      .catch(error => console.error('Erreur:', error));
  };

  const fetchMessages = () => {
    fetch('https://ab-code.fr/api.php?action=getMessages')
      .then(response => response.json())
      .then(data => setMessages(data))
      .catch(error => console.error('Erreur:', error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCar(prevCar => ({
      ...prevCar,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setNewCar({ ...newCar, image: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ici, vous devriez implémenter la logique pour envoyer les données au serveur
    console.log('Nouveau véhicule à ajouter:', newCar);
    // Réinitialiser le formulaire après soumission
    setNewCar({ marque: '', modele: '', annee: '', prix: '', image: null });
  };

  const handleDelete = (carId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette voiture ?')) {
      fetch(`https://ab-code.fr/api.php?action=deleteCar&id=${carId}`, {
        method: 'DELETE',
      })
      .then(response => response.json())
      .then(data => {
        console.log('Succès:', data);
        alert('Voiture supprimée avec succès !');
        fetchCars();
      })
      .catch((error) => {
        console.error('Erreur:', error);
        alert('Erreur lors de la suppression de la voiture');
      });
    }
  };

  // Fonction pour obtenir les détails de la voiture à partir de son ID
  const getCarDetails = (carId) => {
    const car = cars.find(car => car.id === carId);
    return car ? `${car.brand} ${car.model}` : 'Voiture inconnue';
  };

  return (
    <div className="admin-dashboard">
      <h1>Tableau de bord administrateur</h1>
      <div className="admin-tabs">
        <button 
          className={activeTab === 'cars' ? 'active' : ''} 
          onClick={() => setActiveTab('cars')}
        >
          Gestion des voitures
        </button>
        <button 
          className={activeTab === 'requests' ? 'active' : ''} 
          onClick={() => setActiveTab('requests')}
        >
          Demandes d'essai
        </button>
        <button 
          className={activeTab === 'add' ? 'active' : ''} 
          onClick={() => setActiveTab('add')}
        >
          Ajouter une voiture
        </button>
        <button 
          className={activeTab === 'messages' ? 'active' : ''} 
          onClick={() => setActiveTab('messages')}
        >
          Messages
        </button>
      </div>
      
      <div className="admin-content">
        {activeTab === 'cars' && (
          <div className="existing-cars-section">
            <h2>Voitures existantes</h2>
            <div className="car-list">
              {cars.map(car => (
                <div key={car.id} className="car-item">
                  <img src={car.image} alt={`${car.brand} ${car.model}`} className="car-thumbnail" />
                  <div className="car-details">
                    <h3>{car.brand} {car.model}</h3>
                    <p>Année : {car.year}</p>
                    <p>Prix : {car.price} €</p>
                    <p>Kilométrage : {car.mileage} km</p>
                    <p>Carburant : {car.fuel}</p>
                    <p>Transmission : {car.transmission}</p>
                    <button onClick={() => handleDelete(car.id)} className="delete-button">Supprimer</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {activeTab === 'requests' && (
          <div className="test-drive-requests-section">
            <h2>Demandes d'essai des véhicules</h2>
            <table className="test-drive-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Véhicule</th>
                  <th>Date de la demande</th>
                  <th>Date d'essai souhaitée</th>
                </tr>
              </thead>
              <tbody>
                {testDriveRequests.map(request => (
                  <tr key={request.id}>
                    <td>{request.id}</td>
                    <td>{request.car_name}</td>
                    <td>{new Date(request.request_date).toLocaleString()}</td>
                    <td>{new Date(request.test_drive_date).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        
        {activeTab === 'add' && (
          <div className="add-car-section">
            <h2>Ajouter une nouvelle voiture</h2>
            <form onSubmit={handleSubmit} className="add-car-form">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="brand">Marque</label>
                  <input type="text" id="brand" name="brand" value={newCar.brand} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label htmlFor="model">Modèle</label>
                  <input type="text" id="model" name="model" value={newCar.model} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="year">Année</label>
                  <input type="number" id="year" name="year" value={newCar.year} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label htmlFor="price">Prix</label>
                  <input type="number" id="price" name="price" value={newCar.price} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="mileage">Kilométrage</label>
                  <input type="number" id="mileage" name="mileage" value={newCar.mileage} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                  <label htmlFor="fuel">Carburant</label>
                  <select id="fuel" name="fuel" value={newCar.fuel} onChange={handleInputChange} required>
                    <option value="">Sélectionnez un type de carburant</option>
                    <option value="Essence">Essence</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Électrique">Électrique</option>
                    <option value="Hybride">Hybride</option>
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="transmission">Transmission</label>
                  <select id="transmission" name="transmission" value={newCar.transmission} onChange={handleInputChange} required>
                    <option value="">Sélectionnez un type de transmission</option>
                    <option value="Manuelle">Manuelle</option>
                    <option value="Automatique">Automatique</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="image">URL de l'image</label>
                  <input type="url" id="image" name="image" value={newCar.image} onChange={handleInputChange} />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea 
                  id="description" 
                  name="description" 
                  value={newCar.description} 
                  onChange={handleInputChange} 
                  rows="4"
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="image">Photo du véhicule :</label>
                <input 
                  type="file" 
                  id="image" 
                  name="image" 
                  onChange={handleFileChange} 
                  accept="image/*" 
                  required 
                />
              </div>
              <button type="submit" className="submit-button">Ajouter la voiture</button>
            </form>
          </div>
        )}
        {activeTab === 'messages' && (
          <div className="messages-section">
            <h2>Messages des clients</h2>
            <div className="message-list">
              {messages.map(message => (
                <div key={message.id} className="message-item">
                  <h3>Message pour : {getCarDetails(message.car_id)}</h3>
                  <p><strong>De :</strong> {message.name}</p>
                  <p><strong>Téléphone :</strong> {message.phone}</p>
                  <p><strong>Message :</strong> {message.message}</p>
                  <p><strong>Date :</strong> {new Date(message.created_at).toLocaleString()}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminDashboard;
