import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  const isAdmin = localStorage.getItem('isAdmin') === 'true';

  if (!isLoggedIn || !isAdmin) {
    return <Navigate to="/connexion" replace />;
  }

  return children;
}

export default ProtectedRoute;